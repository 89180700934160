.Video.VideoForPreview {
  & {
    position: relative;
    aspect-ratio: initial;
    width: 100%;
    height: 100%; }
  .player {
    width: 100%;
    height: 100%;
    overflow: hidden;
    transition: .25s all ease-out, 0s transform none;
    will-change: width, opacity;
    border-radius: 8px; }
  video.player {
    object-fit: cover;
    border-radius: 8px; } }
