@import 'src/constants';

.Member {
  & {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 0 .25rem 0 .25rem;
    min-height: 2rem;
    transition: .15s all ease-out; }
  @media #{$mobile-landscape} {
    padding-left: var(--viewport-padding-left, .25rem);
    padding-right: var(--viewport-padding-right, .25rem); }
  &:hover,
  &:active,
  &.is-dropdown-open {
    background-color: rgba(#D0D0D5, .5); }
  &.i-can-toggle-more {
    & {
      cursor: pointer; }
    &.on-mobile::after { // A mask to cover all toggles so they become un-clickable on mobile devices.
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      z-index: 100;
      width: 100%;
      height: 100%; } }

  [data-my-role='teacher'] &.teacher,
  [data-my-role='teacher'] &.sub_teacher,
  [data-my-role='teacher'] &.admin,
  [data-my-role='teacher'] &.guest {
 }    //padding-left: 1.375rem // Empty space for 1-on-1 button

  [data-my-role='student'] &,
  [data-my-role='admin'] &,
  [data-my-role='guest'] & {
    & {
      padding: 0 .5rem; }
    @media #{$mobile-landscape} {
      padding-left: var(--viewport-padding-left, .5rem);
      padding-right: var(--viewport-padding-right, .5rem); } }

  Button.with-svg span {
    display: none; }

  Button[data-svg='student-1on1'] {
    & {
      font-size: 1.125rem;
      color: #8A8A8F; }
    svg.Icon path {
      fill: currentColor; }
    &.is-active {
      color: #16BFB7; }
    &.is-active:enabled:hover {
      path[fill='#8A8A8F'] {
        fill: lighten(#16BFB7, 3%) !important; }
      path[stroke='#8A8A8F'] {
        stroke: lighten(#16BFB7, 3%) !important; } } }

  Button:enabled:hover {
    path {
      transition: .15s all ease-out; }
    path[fill='#8A8A8F'] {
      fill: #493B32; }
    path[stroke='#8A8A8F'] {
      stroke: #493B32; } }

  .name {
    margin-left: .125rem;
    max-width: 13em;
    color: #221815; }
  .toggles {
    & {
      display: flex;
      align-items: center;
      margin-left: auto;
      height: 2rem; }
    Button {
      & {
        margin: 0;
        height: 100%;
        font-size: 1.25rem; }
      &:not(:last-child) {
        margin-right: .125rem;
        margin-right: .5rem; } } }
  .functional {
    & {
      display: none;
      padding-right: .25rem; }
    Button {
      & {
        background-color: #fff;
        border-radius: 50%;
        width: 1.5rem;
        height: 1.5rem;
        color: #8A8A8F; }
      &:not(:last-child) {
        margin-right: .375rem; }
      &[data-svg="ellipsis"] {
        path {
          fill: currentColor; }
        &:hover path {
          fill: #16BFB7; } } } }
  .display {
    display: flex; }
  &.i-can-toggle-more:not(.on-mobile):hover,
  &.i-can-toggle-more.is-dropdown-open {
    .display {
      display: none; }
    .functional {
      display: flex; } }
  &.is-dropdown-open {
    Button[data-svg="ellipsis"] path {
      fill: #16BFB7; } }

  [data-my-role='teacher'] &.is-me,
  [data-my-role='sub_teacher'] &.is-me {
    .name {
      max-width: 10em; } }
  [data-my-role='teacher'] &.is-me,
  [data-my-role='teacher'] &.sub_teacher,
  [data-my-role='teacher'] &.admin,
  [data-my-role='sub_teacher'] &.teacher,
  [data-my-role='sub_teacher'] &.sub_teacher,
  [data-my-role='sub_teacher'] &.admin {}
  .Button[data-svg^="screen-share"] {
    transform: translateY(-1px); }

  .info:not(:empty) {
    &::before,
    &::after {}
    &::before {
      content: '（';
      margin-left: -.5em; }
    &::after {
      content: '）';
      display: inline-block;
      width: .5em; } }

  .role {
    display: flex;
    align-items: center;
    margin-left: .125rem;
    margin-right: .125rem;
    padding: 0 .25rem;
    height: .875rem;
    font-size: .625rem;
    border-radius: 1px; }
  &.teacher .role,
  &.sub_teacher .role {
    background-color: #EDEBEB;
    color: #493B32; }
  &.admin .role {
    background-color: #E8F4FF;
    color: #1890FF; }
  &.guest .role {
    background-color: #FFF4E8;
    color: #FA8C16; }

  &.student .role {
    display: none; }

  [data-my-role='teacher'] & .Subjects {
 } }    //padding-left: 1.375rem

.MemberMoreOptions {
  .Ellipsis {
    max-width: 15em; }
  main .content:not(:empty) {
    padding: 0;
    height: auto;
    min-height: initial; }
  ul {
    margin: 0;
    padding: 0 0 0 1rem;
    list-style: none; }
  li {
    & {
      display: flex;
      align-items: center; }
    &:empty {
      display: none; } }

  .Button.with-svg {
    & {
      width: 100%;
      height: 46px;
      justify-content: flex-start;
      border-bottom: 1px solid rgba(#D0D0D5, .5); }
    &:disabled {
 } }      // display: none
  .Button[data-svg='student-1on1'] {
    .Icon {
      color: #8A8A8F; }
    .Icon path {
      fill: currentColor; }
    &.is-active .Icon {
      color: #16BFB7; } }

  .Icon {
    margin-right: .25rem;
    font-size: 1.5rem; } }

.MemberFunctionalMenu {
  & {
    margin-top: .25rem;
    box-shadow: 0px 8px 28px 8px #0000000D, 0px 5px 16px 0px #00000014, 0px 1px 6px -4px #00000017;
    border: 0;
    border-radius: 4px; }
  ul {
    margin: 0;
    padding: 0;
    list-style: none; }

  .Button.with-svg {
    & {
      padding: 0 .375rem;
      justify-content: flex-start;
      width: 100%;
      height: 2rem;
      transition: .15s all ease-out; }
    &:enabled:hover,
    &:enabled:active {
      background-color: #D0D0D580; }
    &:disabled {
      opacity: .5; }
    svg {
      & {
        margin-right: .25rem;
        font-size: 1.25rem; }

      &[data-name="stroke/switch-camera"] {
        path[stroke] {
          stroke: #493B32; }
        path:not([stroke]) {
          fill: #493B32; } }
      &:not([data-name="stroke/switch-camera"]) {
        path, rect {
          fill: #493B32; } } }

    &[data-svg='student-1on1'].is-active svg path, {
      fill: #16BFB7; }
    .mic-volume {
      display: none; } } }
