@import 'src/constants';

.VideoCanvas {
  & {
    animation: VideoCanvas-loading 5s infinite;
    background-color: #eee;
    background-position: center;
    background-repeat: no-repeat; }
  canvas {
    // max-width: 100%
    object-fit: fill; }
  canvas[data-orientation="landscape"] {
    aspect-ratio: 640 / 360; }
  canvas[data-orientation="portrait"] {
    aspect-ratio: 360 / 640;
    object-fit: cover; } }

@keyframes VideoCanvas-loading {
  0% {
    background-color: #eee; }
  50% {
    background-color: #ccc; }
  100% {
    background-color: #eee; } }

