@import 'src/constants';

.TimerToggle {
  & {
    margin-left: 1em;
    min-width: 146px; }
  @media (max-width: 1024px) {
    & {
      min-width: 140px; }
    .Dashboard.interview & {
      padding: 0 .5em;
      min-width: 100px; } }
  @media (max-width: 768px) {
    & {
      min-width: 3.75rem;
      margin-left: .5em; }
    .Dashboard.interview & {
      min-width: 88px;
      font-size: .75rem; }
    .precise {
      display: none; }
    .concise {
      display: inline; } } }
