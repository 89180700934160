html.LP {
  &, body, #root {
    width: 100%;
    height: auto !important;
    overflow: auto !important;
  }

  .notice-message-container {
    height: auto !important;
  }
}

.lp {
  * {
    box-sizing: content-box;
    margin: 0;
    padding: 0;
  }

  .h-center {
    text-align: center;
    margin: 0 auto;
    max-width: 960px;
  }

  header {
    width: 100%;
    z-index: 9999;
    background: #FFF;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    position: fixed;
    top: 0;
    box-sizing: border-box;
    font-family: \\30E1\30A4\30EA\30AA, Meiryo, \\30D2\30E9\30AE\30CE\89D2\30B4 Pro W3, HiraKakuProN-W3, Hiragino Kaku Gothic Pro, NanumGothic, Malgun Gothic, sans-serif;
    font-size: 16px;
    height: 80px;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-align: center;
    align-items: center;
    align-self: stretch;
    padding: 0 40px;

    .logo-img {
      width: 172px;
      height: 40px
    }

    .header-title {
      font-weight: bold;
      margin-left: 16px;
      line-height: 20px;
      color: #493b32;
      font-size: 14px;
      width: 170px
    }

    ul {
      list-style: none
    }

    li > ul, li > ol {
      margin: 0 !important;
    }

    @media screen and (max-width: 1250px) and (min-width: 801px) {
      .header-link-area .sub-link {
        display: none;
      }
    }
    @media screen and (max-width: 940px) {
      .header-title {
        display: none
      }
    }

    .login-btn {
      height: 32px;
      padding: 0 10px;
      border-radius: 16px;
      background-color: #fddc3e;
      text-align: center;
      line-height: 32px;
      font-weight: bold
    }

    .login-btn a {
      text-decoration: none;
      color: #493b32
    }

    .arrow-mobile {
      display: none
    }

    @media screen and (min-width: 801px) {
      .more-info {
        padding-right: 24px
      }
    }

    .more-info a {
      position: relative
    }

    .more-info:hover .arrow-pc {
      transform: rotate(-180deg)
    }

    .more-info:hover .arrow-pc:before, .more-info:hover .arrow-pc:after {
      border: 1px solid #fddc3e
    }

    .arrow-pc {
      transition: 0.3s transform ease-in-out;
      display: block;
      width: 12px !important;
      height: 8px !important;
      overflow: hidden;
      position: absolute;
      right: -24px;
      top: 50%;
      margin-top: -4px
    }

    .arrow-pc:before, .arrow-pc:after {
      content: "";
      position: absolute;
      display: block;
      width: 6px;
      border: 1px solid #493b32;
      border-radius: 1px;
      top: 4px
    }

    .arrow-pc:before {
      transform: rotate(45deg);
      left: 0
    }

    .arrow-pc:after {
      transform: rotate(-45deg);
      left: 4px
    }

    .sub li {
      line-height: 2.5em;
      padding: 0 2em
    }

    .sub li a {
      font-weight: normal
    }

    @media screen and (min-width: 801px) {
      .header-link:hover > a, .news-list:hover a, .header-link a:hover {
        color: #fddc3e
      }

      .sub li:hover {
        background-color: #FEFAC6
      }

      .sub li a:hover {
        color: #493b32
      }

      .more-info:hover .sub {
        height: 400px;
        visibility: visible
      }

      .sub {
        background-color: #fff;
        border-radius: 8px;
        position: absolute;
        text-align: center;
        margin-left: -72px;
        box-shadow: 0 2px 8px rgba(26, 26, 26, 0.2);
        height: 0;
        transition: 0.3s height ease-in-out;
        padding: 12px 0;
        overflow: hidden;
        box-sizing: content-box;
        visibility: hidden
      }
    }

    @media screen and (max-width: 800px) {
      &.new-header {
        height: 44px;
        padding: 0 16px
      }
      .logo-link {
        height: 23px;
      }

      .header-link:hover > a {
        color: #493b32 !important
      }

      .logo-img {
        width: 86px;
        height: 20px
      }

      .login-btn {
        display: none
      }

      .header-link a {
        position: relative
      }

      .arrow-mobile {
        display: block;
        top: 25px;
        right: 0
      }

      .arrow-pc {
        display: none
      }

      .nav-header {
        width: 100%
      }

      .nav-header.w-half {
        width: 50%;
        transition: 0.3s width ease-in-out
      }

      .more-info .sub {
        box-shadow: none;
        position: relative;
        padding: 0;
        overflow-y: auto;
        box-sizing: content-box;
        visibility: hidden;
        height: 0;
        transition: 0.3s opacity ease-in-out;
        opacity: 0;
        width: 0;
        background: #f9f9f9
      }

      .more-info .sub li {
        line-height: 20px;
        height: 70px;
        border-bottom: 1px solid #493b32;
        margin: 0 16px;
        padding: 0
      }

      .more-info .sub li:first-child {
        margin-top: 16px
      }

      .more-info .sub a {
        font-size: 14px !important;
        padding: 0 37px 0 0;
        height: 100%;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-flex: 1;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-item-align: stretch;
        align-self: stretch;
        align-items: center
      }

      .more-info.active a {
        color: #fddc3e !important
      }

      .more-info.active .icon-arrow-right:before, .more-info.active .icon-arrow-right:after {
        border: 1px solid #fddc3e
      }

      .more-info.active .sub {
        width: 50%;
        height: 100%;
        opacity: 1;
        visibility: visible;
        position: absolute;
        right: 0;
        top: 0
      }

      .more-info.active .sub a {
        color: #493b32 !important
      }

      .more-info.active .sub .icon-arrow-right:before, .more-info.active .sub .icon-arrow-right:after {
        border: 1px solid #493b32 !important
      }
    }

    .header-link-area {
      text-align: right;
      -ms-flex: 1;
      flex: 1
    }

    .header-link {
      margin-right: 24px;
      cursor: pointer
    }

    .header-link a {
      color: #493b32;
      text-decoration: none;
      font-weight: bold
    }

    .nav-header {
      display: -ms-flexbox;
      display: flex;
      -webkit-box-flex: 1;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-item-align: stretch;
      align-self: stretch;
      -ms-flex-pack: end;
      justify-content: flex-end;
      -ms-flex-align: end;
      align-items: flex-end
    }

    .header-link-mobile {
      display: none
    }

    #logo img {
      width: 330px
    }

    .menu-box {
      display: none
    }

    @media only screen and (max-width: 800px) {
      #logo {
        text-align: left
      }

      .login {
        display: none
      }

      .nav-header {
        display: block;
        text-align: left
      }

      .menu-box {
        display: block
      }

      header .inner {
        padding: 5px 16px
      }

      header .inner .login-mobile {
        display: block;
        position: absolute;
        right: 60px
      }

      header .inner .login-mobile a {
        width: 68px;
        height: 63px;
        display: block;
        position: relative;
        text-indent: -1em;
        font-size: .5em;
        overflow: hidden;
        text-align: center;
        color: #FFF;
        background: #16bFb7;
        text-decoration: none
      }

      header .inner .login-mobile a .fa-sign-out {
        position: absolute;
        right: 22px;
        font-size: 22px;
        line-height: 50px
      }

      header .inner .login-mobile a .link-text {
        position: absolute;
        width: 58px;
        left: 10px;
        top: 42px
      }

      .header-link-area.active {
        visibility: visible
      }

      .header-link-area {
        overflow: scroll;
        position: absolute;
        top: 44px;
        left: 0;
        width: 100%;
        height: 100vh;
        background: #FFF;
        padding-bottom: 44px;
        visibility: hidden;
        border-top: 1px solid rgba(0, 0, 0, 0.2)
      }

      .content {
        padding: 80px 16px
      }

      .header-link {
        display: block;
        line-height: 70px;
        font-size: 16px;
        color: #493b32;
        border-bottom: 1px solid #d0d0d5;
        margin: 0 16px 0 16px !important;
        text-align: left;
        font-weight: bold
      }

      .header-link a {
        display: block;
        position: relative;
        font-size: 1.2em;
      }

      .header-link .sub a {
        font-weight: bold !important
      }

      .menu-login-mobile {
        color: #FFF !important;
        background: #16bFb7;
        text-decoration: none;
        display: inline-block;
        border-radius: 32px;
        padding: 0px 15px;
        position: relative;
        height: 42px;
        line-height: 42px;
        margin-top: 10px
      }

      .menu-login-mobile a {
        color: #FFF
      }

      .header-link .btn-green {
        padding: 1em 4em
      }

      .menu-box {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: center;
        justify-content: center;
        -ms-flex-align: center;
        align-items: center;
        float: left;
        overflow: hidden;
        z-index: 100;
        width: 60px
      }

      .menu-box:before {
        content: " ";
        display: block;
        padding-top: 100%
      }

      .hamburger {
        width: 24px;
        cursor: pointer
      }

      #hamburger-1.active .line:nth-child(1) {
        transform: translateY(8px) rotate(45deg)
      }

      #hamburger-1.active .line:nth-child(2) {
        opacity: 0
      }

      #hamburger-1.active .line:nth-child(3) {
        transform: translateY(-8px) rotate(-45deg)
      }

      .hamburger .line {
        display: block;
        width: 100%;
        height: 4px;
        border-radius: 4px;
        background-color: #493B32;
        margin: 4px 0;
        transition: all 0.3s ease-in-out
      }

      .hamburger .line:first-child {
        margin: 0 0 4px 0
      }

      .hamburger .line:last-child {
        margin: 4px 0 0 0
      }
    }

    .icon-arrow-right {
      width: 12px !important;
      height: 20px !important;
      overflow: hidden;
      position: absolute;
    }

    .icon-arrow-right:before,
    .icon-arrow-right:after {
      content: "";
      position: absolute;
      display: block;
      width: 11px;
      border: 1px solid #493B32;
      border-radius: 1px;
      top: 5px;
    }

    .icon-arrow-right:before {
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      left: 0px;
    }

    .icon-arrow-right:after {
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
      top: 13px;
    }

    .elementor-inner {
      padding-top: 80px
    }

    @media screen and (max-width: 800px) {
      .elementor-inner {
        padding-top: 44px
      }
    }
  }

  @media screen and (max-width: 639px) {
    footer {
      padding: 0 16px
    }
  }

  footer {
    background-color: #fddc3e;

    .p-mark {
      max-width: 960px;
      margin: 0 auto;
      text-align: left
    }

    .link-group {
      padding-top: 80px
    }

    @media screen and (min-width: 640px) {
      .link-group {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: justify;
        justify-content: space-between
      }
    }

    .link-group .footer-nav-section {
      display: inline-block;
      text-align: left;
      margin-bottom: 40px;
      width: 100%;
    }

    .link-group .footer-nav-section p {
      font-size: 16px;
      font-weight: 700;
      font-style: normal;
      font-stretch: normal;
      color: #493b32;
      margin-bottom: 10px;
      position: relative
    }

    .link-group .footer-nav-section p .icon-arrow-bottom {
      top: 6px;
      right: 0
    }

    @media screen and (min-width: 640px) {
      .link-group .footer-nav-section p .icon-arrow-bottom {
        display: none;
      }
    }

    @media screen and (max-width: 639px) {
      .link-group .footer-nav-section p {
        cursor: pointer;
        padding-bottom: 24px;
        margin-bottom: 0
      }

      .link-group .footer-nav-section p img {
        display: inline-block;
        width: 48px;
        height: 48px;
        transform: rotate(90deg);
        position: absolute;
        top: -12px;
        right: 0
      }
    }

    @media screen and (max-width: 639px) {
      .link-group .footer-nav-section {
        box-sizing: content-box;
        margin-bottom: 0;
        padding-bottom: 16px;
        overflow: hidden
      }

      .link-group .footer-nav-section .link-list {
        max-height: 0;
        visibility: hidden
      }

      .link-group .footer-nav-section .link-list:first-child {
        margin-top: 0
      }
    }
    @media only screen and (max-width: 800px) {
      .footer-link {
        padding: 1em 0;
      }
    }

    .link-group .footer-nav-section .footer-link {
      font-size: 14px;
      color: #493b32;
      text-decoration: none;
      display: block;
      margin-top: 6px
    }

    .link-group .footer-nav-section.active .icon-arrow-bottom {
      transform: rotate(180deg)
    }

    .link-group .footer-nav-section.active .link-list {
      transition: .3s max-height ease-in-out;
      max-height: 520px;
      visibility: visible
    }

    .registration {
      color: #493b32;
      line-height: 60px;
      font-size: 14px;
      border-top: 1px solid #493b32
    }

    @media screen and (max-width: 639px) {
      .registration {
        margin-top: 40px
      }
    }


    .icon-arrow-bottom {
      width: 20px !important;
      height: 12px !important;
      overflow: hidden;
      position: absolute;
    }

    .icon-arrow-bottom:before,
    .icon-arrow-bottom:after {
      content: "";
      position: absolute;
      display: block;
      width: 10px;
      border: 1px solid #493b32;
      border-radius: 1px;
      top: 5px;
    }

    .icon-arrow-bottom:before {
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      left: 0;
    }

    .icon-arrow-bottom:after {
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
      left: 8px;
    }
  }

  .content-container {
    background-color: #fddc3e;
    padding-top: 80px;
    @media screen and (max-width: 800px) {
      padding-top: 44px;
    }

    .lp-title {
      text-align: left;
      max-width: 1100px;
      padding: 0 20px 0 120px;
      position: relative;
      @media screen and (min-width: 801px) {
        display: flex;
        margin: 57px auto 0 auto;
      }
      @media screen and (max-width: 800px) {
        margin: 16px auto 0 auto;
      }

      .air-logo {
        position: absolute;
        top: 0;
        left: 20px;
        width: 80px;
        height: 80px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        content: ' ';
        background-image: url('../assets/lp/air-logo.png');
        margin-right: 21px;
      }
      .h-keyword{
        display: none;
      }
      h1 {
        margin: 0;
        padding: 0;
        color: #493B32;
        font-size: 50px;
        line-height: 50px;
        @media screen and (max-width: 1050px) {
          font-size: 40px;
          line-height: inherit;
        }
        @media screen and (max-width: 900px) {
          font-size: 36px;
          line-height: inherit;
        }
        @media screen and (max-width: 850px) {
          font-size: 32px;
          line-height: inherit;
        }
        @media screen and (max-width: 550px) {
          font-size: 24px;
          line-height: inherit;
        }
        @media screen and (max-width: 400px) {
          font-size: 16px;
          line-height: inherit;
        }
      }

      h2 {
        @media screen and (max-width: 1050px) {
          font-size: 13px;
        }
        margin: 0;
        padding: 0;
        color: #493B32;
        font-size: 16px;
        line-height: 24px;
        font-weight: normal;
      }
    }

    .center-block {
      flex: 1;
    }

    @media screen and (max-width: 800px) {
      .pin-code {
        display: none;
      }
    }

    .pin-code {
      background: #FFFFFF;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
      border-radius: 0 0 8px 8px;
      padding: 18px;
      margin-top: -57px;
      .title-area{
        display: flex;
        align-items: flex-end;
        margin-bottom: 9px;
        .cat{
          width: 98px;
          height: 82px;
        }
        .pin-code-title {
          width: 127px;
          color: #493B32;
          font-size: 13px;
          line-height: 24px;
          &:before{
            display: block;
            content: '';
            width: 97px;
            height: 4px;
            background: #FDDC3E;
          }
        }
      }

      .input-area {
        position: relative;
        width: 237px;

        input {
          width: 100%;
          height: 32px;
          background: #FFFFFF;
          border: 1px solid #D0D0D5;
          box-sizing: border-box;
          border-radius: 4px;
          outline: none;
          padding: 4px 8px;
        }

        button {
          outline: none;
          cursor: pointer;
          position: absolute;
          font-weight: bold;
          font-size: 14px;
          right: 4px;
          top: 50%;
          margin-top: -12px;
          color: #ffffff;
          background: #16BFB7;
          border-radius: 4px;
          width: 76px;
          height: 24px;
        }
      }
    }

    .platform-mobile {
      padding: 24px 20px 0 20px;
      display: none;
      @media screen and (max-width: 800px) {
        display: flex;
        justify-content: center;
      }

      img {
        width: 140px;
        height: 42px;
        &:last-child{
          margin-left: 7px;
        }
      }
    }

    .air-photo {
      display: flex;
      margin: 61px auto 0 auto;
      max-width: 1100px;
      padding: 0 20px;
      overflow-x: auto;
      position: relative;
      &::-webkit-scrollbar {
        display: none;
      }
      @media screen and (min-width: 801px) {
        margin: 61px auto 0 auto;
      }
      @media screen and (max-width: 800px) {
        margin: 12px auto 0 auto;
        .qr {
          display: none;
        }
      }
      @media screen and (max-width: 800px) {
        .pc {
          display: none;
        }
        .mobile {
          left: -29px !important;

          .mobile3 {
            right: -354px;
            left: auto !important;
            top: 0;
          }
        }
      }

      .qr {
        .platform {
          &:nth-child(2) {
            margin-top: 33px;
          }

          img {
            display: block;
            width: 100px;
            height: 100px;
            margin: 0 auto;
            margin-top: 12px;

            &.platform-icon {
              cursor: pointer;
              margin-top: 0;
              width: 140px;
              height: 100%;
            }
          }
        }
      }

      .pc {
        img {
          width: 653px;
          height: 322px;
        }
      }

      .mobile {
        position: relative;
        display: flex;
        left: -49px;

        .mobile1 {
          width: 190px;
          height: 354px;
        }

        .mobile2 {
          width: 190px;
          height: 354px;
        }

        .mobile3 {
          position: absolute;
          left: -234px;
          bottom: 0;
          height: 190px;
          width: 354px;
        }
      }
    }

    .air-feature-container {
      background: #ffffff;
      @media screen and (min-width: 801px) {
        margin-top: 38px;
      }
      @media screen and (max-width: 800px) {
        margin: 12px auto 0 auto;
        .qr {
          display: none;
        }
      }
      .air-feature {
        @media screen and (min-width: 801px) {
          display: flex;
        }
        margin: 0 auto;
        max-width: 1100px;
        padding: 30px 20px;
        align-items: center;

        .feature {
          position: relative;
          flex: 1;

          .feature-title {
            color: #493B32;
            font-weight: bold;
            font-size: 14px;
            line-height: 24px;
            text-align: center;
            margin-bottom: 14px;
          }

          .feature-content {
            color: #493B32;
            font-size: 14px;
            line-height: 24px;
            text-align: center;
          }
        }

        i {
          display: block;
          margin-top: 2px;
          content: '';
          width: 60px;
          height: 5px;
          background: #FFD301;
          transform: rotate(-90deg);
        }
        @media screen and (max-width: 800px) {
          i{
            transform: rotate(0);
            margin: 30px auto;
          }
        }
      }
    }
  }
}
