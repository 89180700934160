@import 'src/constants';

.QuickMessages {
  & {
    position: absolute;
    left: 0;
    bottom: 49px;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 20em;
    height: fit-content;
    max-height: 50vh;
    margin: 0 0 1rem 0;
    padding: 0;
    cursor: pointer;
    transition: .15s all ease-out;
    user-select: none; }
  .QuickChat.is-disabled & {
    display: none; }
  @media #{$mobile-portrait} {
    input:not(:focus) ~ & {
      margin-bottom: 0; } }
  &:empty,
  &.read {
    opacity: 0;
    pointer-events: none;
    cursor: default; }
  li {
    position: relative;
    margin-top: .25rem;
    padding: .25rem .5rem .25rem 1.5rem;
    width: fit-content;
    max-width: 20em;
    min-width: 5rem;
    line-height: 1.5;
    white-space: pre-wrap;
    word-break: break-all;
    animation: .25s ease-out 1 forwards fade-in, .5s ease-out 5s 1 forwards fade-out;
    background-color: rgba(0,0,0,.6);
    border-radius: 13px;
    color: #fff; }
  b {
    &::after {
      content: ':';
      margin-right: .25rem; }
    .Ellipsis {
      max-width: 6em; }
    .DM {
      display: inline-block;
      font-weight: normal;
      color: #FA8C16; } }
  .avatar {
    position: absolute;
    top: .25rem;
    left: .25rem;
    box-sizing: content-box;
    margin-right: .25rem;
    width: 1rem;
    border: 1px solid rgba(0,0,0,.03); }
  svg.avatar {
    font-size: 1rem; } }

@keyframes fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fade-out {
  to {
    opacity: 0; } }
