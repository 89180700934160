@import 'src/constants';

ul.Toaster {
  & {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    z-index: 2000;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    margin-top: 4rem;
    padding: 0;
    width: 100%;
    height: 100%;
    list-style: none;
    pointer-events: none; } }
.Toast {
  & {
    margin: 0 .5rem .5rem;
    padding: .25rem .75rem;
    font-size: .75rem;
    line-height: 2;
    animation: .25s ease-out 1 forwards fade-in, .5s ease-out 3s 1 forwards fade-out;
    background-color: rgba(0,0,0,.8);
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,.25);
    border-radius: 6px;
    color: #fff; }
  .Ellipsis {
    line-height: inherit; }
  @media #{$mobile} {
    .Toaster.is-chat-on &.new-message {
      display: none; } } }
