.Message {
  & {
    margin: .625rem 0 .75rem;
    width: 100%;
    font-size: .875rem; }
  .metadata {
    display: flex;
    align-items: baseline;
    margin-bottom: .25rem;
    width: 100%;
    font-size: .75rem;
    color: #8A8A8F; }
  .from-to {
    display: flex;
    align-items: baseline; }

  .Ellipsis {
    max-width: 7em; }
  .DM {
    margin-left: -.25em;
    margin-right: -.5em; }
  time {
    margin-left: .5em;
    color: transparent;
    transition: .15s all ease-out; }
  &:hover time {
    color: #C4C4C7; }
  p {
    margin: 0;
    padding: .5rem;
    width: max-content;
    max-width: calc(17em + 1rem + 2px);
    line-height: 1.5;
    white-space: pre-wrap;
    word-break: break-all;
    border: 1px solid #dbe2e5;
    border-radius: 4px; }

  a:link,
  a:visited {
    & {
      color: #16BFB7; }
    &:hover {
      color: lighten(#16BFB7, 2%); } }

  &.from-me {
    .metadata {
      justify-content: flex-end;
      .from-to {
        order: 2; }
      time {
        order: 1;
        margin-right: .5em; } }
    p {
      margin-left: auto;
      background-color: #e7f6ff; } } }

@keyframes loading-progress {
  0% {
    transform: translateX(-100%); }
  70%, to {
    transform: translateX(100%); } }

.Message.file p.file {
  & {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: initial;
    height: 3.75rem;
    line-height: 1.333;
    background-color: #fff;
    border-color:#E7E7EA {}
    cursor: pointer; }

  &.is-uploading {
    cursor: default; }

  img.preview[src] {
    & {
      margin-right: 12px;
      width: 36px;
      height: 36px;
      border: 1px solid #E7E7EA;
      border-radius: 4px; }
    & + .Icon {
      display: none; } }

  .preview + .Icon {
    margin-right: 12px;
    width: 36px;
    height: 36px; }

  .detail {
    flex: 1; }

  b.name {
    display: block;
    margin-bottom: 2px;
    max-width: 16em;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: #221815; }

  b.size {
    & {
      display: flex;
      align-items: center;
      font-weight: normal;
      color: #8A8A8F; } }

  &.is-uploading b.size {
    justify-content: space-between; }

  &:not(.is-uploading):hover b.size::after {
      content: ' - click to download'; }
  &.image:not(.is-uploading):hover img[src] ~ .detail b.size::after {
      content: ' - click to preview'; }

  .loading-indicator {
    & {
      position: relative;
      flex: 1;
      margin-left: 1em;
      height: 4px;
      overflow: hidden;
      background-color: #eee;
      border-radius: 2px; }

    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      animation: 2.5s loading-progress 0 linear;
      animation-iteration-count: infinite;
      background-image: linear-gradient(150deg,rgba(#1890FF,0) 0%,#1890FF 50%,rgba(238,238,238,0) 100%);
      border-radius: inherit; } } }
