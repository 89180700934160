.VideoForScreenSharing {
  & {
    position: relative;
    aspect-ratio: initial;
    background-color: transparent; }
  &:not(.is-open) {
    margin-right: 0 !important;
    width: 0 !important; }

  .player {
    & {
      position: relative;
      width: 100%;
      height: 100%;
      transition: .25s all ease-out;
      will-change: width, opacity; }
    &:empty {
      &::after {
        content: '画面共有読み込み中…';
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%; } }
    > div:only-child {
      background-color: transparent !important; } }
  &.i-am-blinded-from-screen-sharing .player:empty::after {
    content: none; }
  &:not(.is-open) {
    width: 0;
    opacity: 0;
    pointer-events: none; }
  #player_7 {
    background-color: transparent !important; } }
